import validate from "/data/www/mijn.khn.nl/mijn-khn/releases/78/nuxt/node_modules/.pnpm/nuxt@3.14.159_@parcel+watcher@2.5.0_@types+node@22.9.0_eslint@8.57.1_ioredis@5.4.1_lightningc_jr7wpywqet2gby5zuir4nonaxm/node_modules/nuxt/dist/pages/runtime/validate.js";
import redirect_45trailing_45slash_45global from "/data/www/mijn.khn.nl/mijn-khn/releases/78/nuxt/node_modules/.pnpm/@bttr-devs+nuxt-layer-base@1.1.0_@bugsnag+core@7.25.0_magicast@0.3.5_nuxt@3.14.159_@parcel+wa_weyegvxxzx36465bigoza3yroa/node_modules/@bttr-devs/nuxt-layer-base/middleware/redirect-trailing-slash.global.ts";
import manifest_45route_45rule from "/data/www/mijn.khn.nl/mijn-khn/releases/78/nuxt/node_modules/.pnpm/nuxt@3.14.159_@parcel+watcher@2.5.0_@types+node@22.9.0_eslint@8.57.1_ioredis@5.4.1_lightningc_jr7wpywqet2gby5zuir4nonaxm/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  redirect_45trailing_45slash_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  "sofie-page": () => import("/data/www/mijn.khn.nl/mijn-khn/releases/78/nuxt/node_modules/.pnpm/@bttr-devs+nuxt-layer-sofie@1.4.1_@bugsnag+core@7.25.0_graphql-ws@5.16.0_graphql@16.9.0__magi_xlmxxvfauweh57nwqxwbbgytle/node_modules/@bttr-devs/nuxt-layer-sofie/middleware/sofie-page.ts")
}